import { createStore } from 'vuex'

// Create a new store instance.
const store = createStore({
  state () {
    return {
      database: null,
      produtos: [],
      categorias: [],
      modelos: [],
      marcas: [],
      dadosEmpresa: [],
      dialog: false,
      carregando: false,
      count: 0,
      images: [],
      banners: [],
      produtoSelecionado: [],
      salvando: false
    }
  },
  mutations: {
    incrementSalvando (state, dados) {
      state.salvando = dados
    },
    incrementProdutoSelecionado (state, dados) {
      state.produtoSelecionado = dados
    },
    incrementCount (state, dados) {
      state.count = dados
    },
    incrementImages (state, dados) {
      state.images = dados
    },
    incrementBanners (state, dados) {
      state.banners = dados
    },
    incrementMarcas (state, dados) {
      state.marcas = dados
    },
    incrementModelos(state, dados) {
      state.modelos = dados
    },
    incrementCarregando (state, dados) {
      state.carregando = dados
    },
   
    incrementDatabase (state, dados) {
      state.database = dados
    },
    incrementProdutos (state, dados) {
      state.produtos = dados
    },
    incrementCategorias (state, dados) {
      state.categorias = dados
    },
    
    incrementDadosEmpresa (state, dados) {
      state.dadosEmpresa = dados
    },
    incrementDialog (state, dados) {
      state.dialog = dados
    },
   
  },
  getters: {
    // ...
    images(){
      return store._state.data.images
    },
    banners(){
      return store._state.data.banners
    },
    salvando(){
      return store._state.data.salvando
    },
    produtoSelecionado() {
      return store._state.data.produtoSelecionado
    },
        count(){
          return store._state.data.count
        },
        modelos(){
          return store._state.data.modelos
        },
        carregando() {
          return store._state.data.carregando
        },
        database () {
          return store._state.data.database
        },
        produtos () {
          return store._state.data.produtos
        },
        categorias () {
          return store._state.data.categorias
        },
        marcas () {
          return store._state.data.marcas
        },
        dadosEmpresa () {
          return store._state.data.dadosEmpresa
        },
        dialog () {
          return store._state.data.dialog
        }
    },
  })

export { store }