// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCkawZ5cdS8aMwrggUwMQVxHp-2MIlLhi0",
  authDomain: "zanoncaminhoes-bf725.firebaseapp.com",
  projectId: "zanoncaminhoes-bf725",
  storageBucket: "zanoncaminhoes-bf725.appspot.com",
  messagingSenderId: "842260426337",
  appId: "1:842260426337:web:8aab63afab12d8525b8420",
  measurementId: "G-Y3N1Q02L6C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

export { app, db, analytics };

