import { createWebHistory, createRouter } from 'vue-router';

import Login from '../pages/Login/Login.vue';
import DadosCad from '../pages/DadosCad/DadosCad.vue';



const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    props: true,
  },
  {
    path: '/dadoscadastrais',
    name: 'dadosCadastraisParent',
    component: () => import("../pages/DadosCad/DadosCadParent"),
    props: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        component: DadosCad,
        name: "DadosCad",
        props: true,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "dadosempresa",
            component: () => import("../pages/DadosCad/containers/DadosCad"),
            name: "dadosCadEmpresa",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "banner",
            component: () => import("../pages/DadosCad/containers/Banner"),
            name: "dadosCadBanner",
            props: true,
            meta: {
              requiresAuth: true,
            },
          }
        ],
      },
    ],
  },
  {
    path: '/cadastro',
    name: 'CadastroParent',
    component: () => import("../pages/Cadastro/CadastroParent"),
    props: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        component: () => import("../pages/Cadastro/Cadastro"),
        name: "Cadastro",
        props: true,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "produtos",
            component: () => import("../pages/Cadastro/containers/Produtos"),
            name: "cadastroProdutos",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "categorias",
            component: () => import("../pages/Cadastro/containers/Categorias"),
            name: "cadastroCategorias",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "marcas",
            component: () => import("../pages/Cadastro/containers/Marcas"),
            name: "cadastroMarcas",
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "modelos",
            component: () => import("../pages/Cadastro/containers/Modelos"),
            name: "cadastroModelos",
            props: true,
            meta: {
              requiresAuth: true,
            },
          }
        ]
        }]
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
import { getAuth, onAuthStateChanged } from "firebase/auth";


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        next();
      } else {
        next("/");
      }
    });
  } else {
    next();
  }
});
export default router;
