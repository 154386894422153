import 'vuetify/styles'; // Global CSS has to be imported
import { createApp } from 'vue';

import { store } from './store/store';

import '@mdi/font/css/materialdesignicons.css';

import VueMobileDetection from 'vue-mobile-detection';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';

import App from './App.vue';
//mask
import VueMask from '@devindex/vue-mask';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'


const vuetify = createVuetify({
  components,
  directives,
})

//firebase
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { initializeAuth, browserLocalPersistence } from "firebase/auth"


const firebaseConfig = {
  apiKey: "AIzaSyCkawZ5cdS8aMwrggUwMQVxHp-2MIlLhi0",
  authDomain: "zanoncaminhoes-bf725.firebaseapp.com",
  projectId: "zanoncaminhoes-bf725",
  storageBucket: "zanoncaminhoes-bf725.appspot.com",
  messagingSenderId: "842260426337",
  appId: "1:842260426337:web:8aab63afab12d8525b8420",
  measurementId: "G-Y3N1Q02L6C"
};

// Initialize Firebase
const app1 = initializeApp(firebaseConfig);
const db = getFirestore(app1);


const auth = initializeAuth(app1, {
  persistence: browserLocalPersistence,
});
export { app, db , auth};

//Rotas
import router from './router/index';

const app = createApp(App);


app.use(router);

app.use(VueMask);

app.use(VCalendar, {})

app.use(VueSweetalert2);

app.use(VueMobileDetection);

app.use(vuetify);

app.use(store)


app.mount('#app');