import { db } from "../firebase";
import { onSnapshot, query, collection, where } from "firebase/firestore";
import { store } from "../store/store";

export default async function getProdutos(database) {
    const q = query(collection(db, "produtos"), where("empresa", "==", database));
    onSnapshot(q, (querySnapshot) => {
        let data = []
        querySnapshot.forEach((doc) => {
            const newData = {...doc.data(),  docId:doc.id}
            data.push(newData)
        });
        setTimeout(() => {store.commit('incrementProdutos', data)}, 0);
    });
}
