import { db } from "../firebase";
import { doc,  getDoc } from "firebase/firestore";

import { store } from "../store/store";



export default async function getDatabase(auth){
    var docSnap = await getDoc(doc(db, "usuarios", "usuarios"))

    /*se o estado do autenticado for true ele pega os dados do usuario e procura na tabela usuarios para
    solicitar a informação de banco de dados daquele usuário. Caso tenha, redireciona para a tela de pedidos com o bancos de dados correto do usuario. Verifica quais paginas estao liberadas para aquele mesmo usuario**/
    var promise = new Promise ((resolve) => {
        const teste = auth.email
        var data = docSnap.data()[0].find(o => o.email == teste )
        store.commit('incrementDatabase', data)
        resolve(data)
    })
    return promise
}