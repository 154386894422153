<template>
  <v-card v-if="logado" style="
  " >
  
    <v-layout>
      <v-navigation-drawer
        id="drawer"
        class="d-flex flex-column"
        style="background-color: #D32F2F"
        v-model="drawer"
        :rail="rail"
        permanent
        
      >
      
        <v-list >
          <v-list-item v-if="rail == false" >
            <div class="d-flex justify-center">
              <v-avatar size="125" rounded="0">
                <v-img  :src="this.dadosEmpresa.img_url"></v-img>
              </v-avatar>
            </div>
            
          </v-list-item>
          <v-list-item
            class="text-center text-white"
            v-if="rail == false"
            :title="this.dadosEmpresa.nome" 
          >
          </v-list-item>
        </v-list>

        
    <v-list v-model:opened="open" id="navbarList" >

        <v-list-group value="Controle" v-if="this.controles != ''"
        prepend-icon="mdi-gamepad-round-left"
        >
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              title="Controle"
            ></v-list-item>
          </template>
          <div class="scrollable">
            <v-list-item
          v-for="([title, icon, to], i) in controles" 
            :key="i"
            :to="to"
            :title="title"
            :prepend-icon="icon"
            :value="title"
            :style="rail ? 'margin-left: -55px;' : ''"
          ><v-tooltip
              activator="parent"
              location="end"
            >{{title}}</v-tooltip></v-list-item>
          </div>
        </v-list-group>
         

        <v-list-group value="Cadastro" prepend-icon="mdi-file-edit-outline" v-if="this.cadastros" >
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              title="Cadastro"
            ></v-list-item>
          </template>

          <div class="scrollable">
          <v-list-item
            v-for="([title, icon, to], i) in cruds"
            :key="i"
            :to="to"
            :value="title"
            :title="title"
            :prepend-icon="icon"
            :style="rail ? 'margin-left: -55px;' : ''"
          > <v-tooltip
              activator="parent"
              location="end"
            >{{title}}</v-tooltip></v-list-item>
          </div>
          
        </v-list-group>
        
        <v-tooltip v-if="dadosCadastrais == true" right>
          <template v-slot:activator="{ props }">
            <div
              v-bind="props"
              ><v-list-item
                prepend-icon="mdi-office-building"
                title="Dados Cadastrais"
                :to="{ name: 'DadosCad',}"
              ></v-list-item
            ></div>
          </template>
          <p>Dados Cadastrais</p>
        </v-tooltip>
    </v-list>
       
    <div class="text-right"
        >
          <v-btn
            style="margin-top: 150px;"
            variant="text"
            icon="mdi-chevron-right"
            color="black"
            @click="rail = false"
            v-if="rail == true"
          ></v-btn>
        </div>
        <div class="text-right" >
          <v-btn 
            variant="text"
            icon="mdi-chevron-left"
            color="black"
            @click="rail = true"
            v-if="rail == false"
          ></v-btn>
        </div>
        
        <template v-slot:append>

          <v-list-item class="mb-5 text-center" v-if="!rail" @click="sair()"
            :to="{ name: 'Login' }"
          >
          <v-icon class="mr-2">mdi-exit-to-app</v-icon>Sair<v-tooltip
              activator="parent"
              location="end"
            >Sair</v-tooltip></v-list-item>
            <v-list-item class="mb-5 text-center" v-if="rail" @click="sair()"
            :to="{ name: 'Login' }"
            style="margin-left: -30px"
          > <v-icon>mdi-exit-to-app</v-icon><v-tooltip
              activator="parent"
              location="end"
            >Sair</v-tooltip></v-list-item>
        </template>
        
        
      </v-navigation-drawer>


      <v-main style="height: 100%"><router-view /></v-main>
    </v-layout>
    </v-card>
    <v-col
        v-if="carregamento"
        cols="12"
        class="text-center"
        style="position: absolute; z-index: 9; top: 45%"
      >
      <v-progress-circular indeterminate color="red"></v-progress-circular>
    </v-col>
</template>
<script>
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { doc, updateDoc, collection, query, getDocs } from "firebase/firestore";
import { mapGetters } from "vuex";
import { db } from "../firebase";
import getDatabase from "../libs/getDatabase"
import getProdutos from "../libs/getProdutos"
import getCategorias from "../libs/getCategorias"
import getMarcas from "../libs/getMarcas"
import getModelos from "../libs/getModelos"
import fetchPeriodicImages from "../libs/images/getData"

import getDadosEmpresa from "../libs/getDadosEmpresa"

import { store } from "../store/store";
import fetchPeriodicBanners from "../libs/banners/getData";


export default {
  name: "NavBar",
  data: () => ({
    open: ['Produtos'],
      controles: [],
      cruds: [
        ['Categorias', 'mdi-format-list-bulleted-triangle',  '/cadastro/categorias'],
        ['Marcas', 'mdi-book-open-outline',  '/cadastro/marcas'],
        ['Modelos', 'mdi-truck',  '/cadastro/modelos'],
        ['Produtos', 'mdi-view-list-outline', '/cadastro/produtos'],
    
      ],
    status: "",
    carregamento: false,
    cadastros: false,
    horarios: {},
    dadosCadastrais: false,
    drawer: true,
    novoUser: 'false',
    rail: false,
    logado: false,
    autenticado: getAuth(),
  }),
  watch: {
   
    "dadosEmpresa"(n){
      if(n){
        this.getHorarios()
        if (n.nome){
        this.getDb()
      }
      } 
    }
  },
  computed: {
    ...mapGetters(['database', 'dadosEmpresa'])
  },
  methods: {
    async getDb() {
      
      
      if (this.database) {
        this.logado = true;
        let controles = [ {
            nome: 'Horários', status: false
          }, {
            nome: 'Financeiro', status: false
          }]
        for(let o = 0; o < this.database.acessos.length; o++){
          if(this.database.acessos[o] == 'Cadastros' && this.novoUser == "false"){
            this.cadastros = true
          }
          if(this.database.acessos[o] == 'Dados Cadastrais'){
            this.dadosCadastrais = true
          }
        }
        if(this.dadosCadastrais == false && this.cadastros == false && controles[0].status != true && controles[1].status != true && controles[2].status != true && controles[3].status != true){
          alert('Acesso Negado. Solicite ao suporte para inserir abas a este usuário.')
          this.logado=false
          signOut(this.autenticado).then(() => {
            this.$router.push({ name: "Login" });
            localStorage.removeItem("logado");
            localStorage.removeItem("novoUser");
          });
        }        
        if (
          this.$route.path != "/" && this.cadastros == true
        ) {
          this.$router.push({
            name: "cadastroProdutos",
            
          });
        } else if(this.dadosCadastrais == true){
          this.$router.push({
            name: "dadosCadEmpresa",
          });
        }
        if(this.logado == true){
          setTimeout(() => {this.getEmpresa()}, 0)
          
      }
      } else {
        this.logado = false;
      }
      setTimeout(() => {this.carregamento = false
      }, 250)
      
    },
    getEmpresa() {
        if (this.dadosEmpresa && this.dadosEmpresa.nome != "" ) {
          this.novoUser = "false";
          localStorage.setItem("novoUser", false);
        } else {
          this.novoUser = "true";
          localStorage.setItem("novoUser", true);
          var data = {
            endereco: "",
            cnpj: "",
            horarios: [{}],
            img_url: "",
            nome: "",
            telefones: [{}],
            whats: "",
          };
          store.commit('incrementDadosEmpresa', data)
          if (localStorage.getItem("novoUser") == "true") {
            this.$router.push({
              name: "DadosCad",
            });
          }
        }
        
    },
    getHorarios() {
        this.horarios = [];
        setTimeout(() => {
          if (this.dadosEmpresa != undefined && this.dadosEmpresa != "") {
            if(this.dadosEmpresa.horarios[0].length != undefined){
              this.horarios = this.dadosEmpresa.horarios[0];
            }
          } else {
              this.horarios = {};
          }
          if(this.novoUser == "false" && this.horarios.length > 0){
            this.dianaoUtil();
          }}, 250)
        
    },
    dianaoUtil() {
        const docRef = doc(db, this.database.cnpj, "dadosempresa");
        var diaSemana = new Date().getDay();
        var casosdianaoUtil = [
            diaSemana == 6 && this.horarios.sexta.util == "false",
            diaSemana == 5 && this.horarios.quinta.util == "false",
            diaSemana == 4 && this.horarios.quarta.util == "false",
            diaSemana == 3 && this.horarios.terca.util == "false",
            diaSemana == 2 && this.horarios.segunda.util == "false",
            diaSemana == 1 && this.horarios.domingo.util == "false",
            diaSemana == 0 && this.horarios.sabado.util == "false",
        ];
        if (casosdianaoUtil[0]) {
            this.horarios.sexta.abre = "";
            this.horarios.sexta.fecha = "";
            this.horarios.sexta.util = "";
        }
        if (casosdianaoUtil[1]) {
            this.horarios.quinta.abre = "";
            this.horarios.quinta.fecha = "";
            this.horarios.quinta.util = "";
        }
        if (casosdianaoUtil[2]) {
            this.horarios.quarta.abre = "";
            this.horarios.quarta.fecha = "";
            this.horarios.quarta.util = "";
        }
        if (casosdianaoUtil[3]) {
            this.horarios.terca.abre = "";
            this.horarios.terca.fecha = "";
            this.horarios.terca.util = "";
        }
        if (casosdianaoUtil[4]) {
            this.horarios.segunda.abre = "";
            this.horarios.segunda.fecha = "";
            this.horarios.segunda.util = "";
        }
        if (casosdianaoUtil[5]) {
            this.horarios.domingo.abre = "";
            this.horarios.domingo.fecha = "";
            this.horarios.domingo.util = "";
        }
        if (casosdianaoUtil[6]) {
            this.horarios.sabado.abre = "";
            this.horarios.sabado.fecha = "";
            this.horarios.sabado.util = "";
        }
        var horarios = [this.horarios];
        updateDoc(docRef, {
            "dadosempresa.horarios": horarios,
        });
    },
    
    sair() {
      signOut(this.autenticado).then(() => {
        this.carregamento = false
        this.$router.push("/");
        this.logado = false;
        localStorage.removeItem("logado");
        localStorage.removeItem("novoUser");
        // socket.close()
      });
    },
    async validaUsuario(n){
      this.carregamento = true
      const empresasRef = collection(db, "empresas")
      const q = query (empresasRef)
      const result = await getDocs(q)

      result.forEach((val) => {

        if(Object.keys(val.data()).length == 4){
          if(val.data().estabelecimento.cnpj == n){
            this.status = val.data().status

          }
        }
          
      })
      if(this.status == "Inativo"){
          alert('Empresa Inativa. Entre em contato e regularize seu cadastro.')
          this.logado=false
          signOut(this.autenticado).then(() => {
            this.$router.push({ name: "Login" });
            localStorage.removeItem("logado");
            localStorage.removeItem("novoUser");
          });
        }
    }
  },
  activated(){
    this.validaUsuario()
  },
  async created() {
    this.rail = this.$isMobile() ? true : false
    onAuthStateChanged(this.autenticado, (user) => {
        if (user) {
          this.carregamento = true
          
          getDatabase(user).then(() => {
            this.getDb(user)
            getDadosEmpresa(this.database.cnpj)
            this.validaUsuario(this.database.cnpj)
            if(this.novoUser == "false"){
              getProdutos(this.database.cnpj)
              getCategorias(this.database.cnpj)
              fetchPeriodicImages(this.database.cnpj, true)
              fetchPeriodicBanners(this.database.cnpj, true)
              getMarcas(this.database.cnpj)
              getModelos(this.database.cnpj)
            }
          })
        }})

  },
};
</script>
<style lang="scss" scoped>

.v-list-item:hover {
  color: black;
}

a.router-link {
  width: 100%;
}
a.router-link-active {
  pointer-events: none;
  div {
    filter: opacity(120%);
  }
}

</style>
<style scoped>
  .v-list-item, span{
    min-width: 112px !important
  }
  #navbarList {
  max-height: 200% !important;
  }
  /deep/ .v-navigation-drawer__content{
    overflow-y: auto;
    overflow-x: hidden;

  }
  /deep/ ::-webkit-scrollbar{
  width: 5px !important;
}
/deep/ ::-webkit-scrollbar-thumb {
  border: solid 0px rgb(255, 198, 198);
  background: rgb(255, 198, 198);
  border-radius: 25px;
}
/deep/ ::-webkit-scrollbar-track {
  background: #ea4545
}
 

</style>
