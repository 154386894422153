import { auth } from '@/main';
import { store } from '@/store/store';
import axios from 'axios'

export default function fetchPeriodicImages(empresa, getOnce) {
  store.commit('incrementCarregando', true)
  store.commit('incrementImages', [])
  
    const  getImages = async() => {
      if (auth.currentUser != null) {
        await auth.currentUser?.getIdToken(true).then((log) => {
          axios({
            method: "post",
            url:
              `${process.env.VUE_APP_APIURL}/images/getdata`,
            headers: {
              "authtoken": log
            },
            data: {
              empresa: empresa,
            },
          }).then((res)=>{
            store.commit('incrementImages', res.data)
            store.commit('incrementCarregando', false)
            return(res.data)
          }).catch(()=>{
            store.commit('incrementCarregando', false)
          });
        })
      } else {
        store.commit('incrementCarregando', false)

        return('Unauthorized')
      }
    };
  
    const executeFunction = () => {
        getImages();
    }
    if(getOnce){
      executeFunction(); // initial execute
    } 
  }