import { db } from "../firebase";
import { doc,  onSnapshot } from "firebase/firestore";
import { store } from "../store/store";



export default function getCategorias(database){
    onSnapshot(doc(db, database, "categorias"), (doc) => {
        var data = Object.values(doc.data())
        data = data[0]
        store.commit('incrementCategorias', data)
    })
}

